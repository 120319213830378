.loader-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes ldio-qntsbvumzud {
  0% {
    background: #4f53c4;
  }
  12.5% {
    background: #4f53c4;
  }
  12.625% {
    background: #6eefa9;
  }
  100% {
    background: #6eefa9;
  }
}
.ldio-qntsbvumzud div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #6eefa9;
  animation: ldio-qntsbvumzud 1s linear infinite;
}
.loadingio-spinner-blocks-5lvmud3uq25 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-qntsbvumzud {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-qntsbvumzud div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
