@import "../../scss/colors";

.home {
  background-color: $primary-color;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  text-transform: uppercase;

  .img-dots {
    background: url("../../assets/dots_header.png");
    width: 460px;
    height: 100px;
    margin-bottom: 10px;
  }

  h1 {
    color: $secondary-color;
    font-size: 2.5rem;
  }

  h2 {
    color: $secondary-color;
    font-size: 3.5rem;
  }
  h3 {
    color: $white;
    line-height: 2rem;
  }
}
