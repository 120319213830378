@import '../../scss/colors';

button {
  background: $primary-color;
  border: none;
  color: $white;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  margin: 5px;

  &:hover {
    background-color: $btn-color;
    transition: 0.5s;
  }

  &:disabled {
    background-color: #e2e2e2;
  }

  &.selected {
    background-color: $btn-color;
  }
}
