@import "./scss/reset.scss";
// @import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css?family=Varela+Round:400,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600");

html,
body {
  width: 100%;
  height: 100vh;
  font-family: "Varela Round", sans-serif;
}
