.send-page {
  h4 {
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
  }
  .send-introduction {
    line-height: 1.2;
    margin: 1rem 0;

    span,
    strong {
      font-weight: 700;
    }
  }
  .send-form {
    color: red;
    .send-form__textarea {
      width: 100%;
      font-size: 1.2rem;
      resize: none;
    }

    .send-form__btn-area {
      display: flex;
      justify-content: center;
    }
  }
}

.box {
  padding: 0.5rem;
  border-radius: 10px;
  line-height: 1.2;
  margin: 0 0 1.5rem 0;
  &.error {
    background-color: #ff6961;
  }
  &.warning {
    background-color: #ffb561;
  }
}
