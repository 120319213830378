@import '../../scss/colors';

.introduction-box {
  margin-top: 10em;
  background-color: $babyblue;
  width: 80%;
  margin: 0 auto;
  padding: 0.6rem;
  text-align: center;
  line-height: 1.5;
  border-radius: 15px;
  p {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 2rem;
  }
  .introduction-box--describe {
    strong {
      font-weight: 700;
    }
  }
}
