@import '../../scss/colors';

.container-app {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 300px auto 150px;
  grid-template-areas:
    'header'
    'content'
    'footer';

  .header {
    grid-area: header;
    text-align: center;
  }
  .content {
    grid-area: content;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
    // align-items: center;
    display: grid;
    grid-template-rows: 1fr 8fr;
    .partial-content {
      width: 80%;
      margin: 0 auto;
    }
  }
  .footer {
    grid-area: footer;
  }

  .content-body {
    .menu {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 1080px) {
  .container-app {
    grid-template-rows: 1fr auto 150px;
  }

  .header {
    padding: 1rem;
  }
}
