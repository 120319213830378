@import "../../scss/colors";

.footer {
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;

  img.logo {
    height: 80%;
    width: 150px;
    object-fit: cover;
  }
}
