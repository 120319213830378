@import '../../scss/colors';

.theme-page {
  //   text-align: center;
  height: 100%;
  padding-top: 1rem;
  h4.title {
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
  }

  .menu-theme {
  }

  .theme-example {
    text-align: left;
    line-height: 1.3;
    margin: 0.5rem;
    p {
      margin-bottom: 0.5rem;
    }
  }

  .theme-box--describe {
    margin: 0 auto;
    padding: 10px;
    background: $orange-dash;
    width: 60%;
    text-align: center;
    line-height: 1.5;
    border-radius: 15px;
    margin-bottom: 1rem;


    strong {
      font-weight: 700;
    }
  }
}
