@import "../../scss/colors";

.header {
  background: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.2;
  text-transform: uppercase;

  * {
    margin-bottom: 10px;
  }

  h1.header-h1,
  h2.header-h2 {
    color: $secondary-color;
    font-size: 2rem;
  }

  h2.header-h2 {
    font-size: 4rem;
    text-align: center;
  }

  h3.header-h3 {
    font-size: 2rem;
    color: $white;
  }
}
