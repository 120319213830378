@import '../Content/index.scss';
@import '../../scss/colors';
.result-body {
  background-color: $primary-color;
  font-size: 2rem;
  color: $white;
  font-weight: 400;
  line-height: 1.2;

  section {
    width: 90%;
    margin: 0 auto;
  }
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  text-align: center;

  .hightligth {
    color: $secondary-color;
  }

  .bold {
    font-weight: 700;
  }

  .result-body__essayInfo,
  .result-body__userInfo {
    margin-bottom: 20px;
  }

  .result-body__essayInfo {
    font-size: 1.5rem;

    .title {
      font-size: 2rem;
    }

    div {
      margin-top: 30px;
    }
  }

  .result-body__essay {
    background-color: $white;
    color: $black;
    font-size: 1.5rem;
  }

  .result-body__compenencies {
    font-size: 1rem;
    line-height: 1.5;

    .comtepence_commentary {
      text-align: justify;

      strong {
        font-weight: 700;
      }
    }

    .competencie_grade {
      font-weight: 700;
    }
  }

  iframe {
    width: 80%;
    height: 50vh;
  }
}
